import React, { useEffect} from 'react'
import * as styles from '../../styles/adsense.module.scss'

const AdSense = ({path}) => {
    useEffect(() => {
        if (window) {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
        }
    },[path]) 
    return (
        <div className={styles.adsensewrapper}
            key = {path}
        >
            <ins className="adsbygoogle"
                style={{display: 'block'}}
                data-ad-client="ca-pub-6562620686181061"
                data-ad-slot="6672514083"
                data-ad-format="auto"
                data-full-width-responsive="true"
            />
        </div>
    )    
    
}

export default AdSense