import React from 'react'
import * as styles from '../styles/sidebar.module.scss'
import { createLinkStyle } from './functions/createconst'
import About from './about'


const LinkStyle = createLinkStyle();


function MakeToC({items}){
    return(
        <ul>
            {items.map((item) =>
                <li>
                    <a 
                        href = {item.url}
                        style = {LinkStyle}
                    >
                        {item.title}
                    </a>
                </li>
            )}
        </ul>
    )
}

const SideBar = ({data}) => {


    return(
        <div className={styles.sidebar}>

            <div className = {styles.tocwrapper}>
                <div className={styles.toctitle}>目次</div>
                <MakeToC items = {data.mdx.tableOfContents.items}></MakeToC>
            </div>

            <div className={styles.aboutwrapper}>
                <About isVertical={true}></About>
            </div>
        </div>
    )
}

export default SideBar