import React from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import * as styles from '../styles/relatedposts.module.scss'
import { createLinkStyle } from './functions/createconst'

const RelatedPosts = ({relatedposts, thispostid, thispath}) =>{
    let items = []
    const LinkStyle = createLinkStyle()

    if(relatedposts.nodes.length < 4){
        for(let i = 0; i < relatedposts.nodes.length; i++){
            if(relatedposts.nodes[i].id !== thispostid){
                const thisslug = `${thispath}${relatedposts.nodes[i].fields.slug}`
                items.push(
                    <div className={styles.listitemwrapper} key={thisslug}>
                        <a className={styles.link} 
                            href={thisslug} 
                            style={LinkStyle}
                        >
                            <div className={styles.stackwrapper}>
                                <div className={styles.thisimgwrapper}>
                                    <GatsbyImage className={styles.thisimg}
                                        image={getImage(relatedposts.nodes[i].frontmatter.hero)}
                                    ></GatsbyImage>
                                </div>
                                <div className={styles.thistitle}>
                                    {relatedposts.nodes[i].frontmatter.title}
                                </div>
                            </div>
                        </a>
                    </div>
                )
            }
        }
    }
    if(relatedposts.nodes.length >= 4){
        for(let i = 0; i < 4; i++){
            if(relatedposts.nodes[i].id !== thispostid){
                const thisslug = `${thispath}${relatedposts.nodes[i].fields.slug}`
                items.push(
                    <div className={styles.listitemwrapper}>
                        <a className={styles.link} 
                            href={thisslug} 
                            style={LinkStyle}
                        >
                            <div className={styles.stackwrapper}>
                                <div className={styles.thisimgwrapper}>
                                    <GatsbyImage className={styles.thisimg}
                                        image={getImage(relatedposts.nodes[i].frontmatter.hero)}
                                    ></GatsbyImage>
                                </div>
                                <div className={styles.thistitle}>
                                    {relatedposts.nodes[i].frontmatter.title}
                                </div>
                            </div>
                        </a>
                    </div>
                )
            }
        }
    }


    return(
        <div className={styles.relatedpostswrapper}>
            {items}
        </div>
    )
}

export default RelatedPosts